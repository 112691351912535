module services {
    export module master {
        export class certificateRegisterDetailService implements interfaces.master.ICertificateRegisterDetailService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getAllocatedCertificateRegisterDetail(comId: number, documentType: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CertificateRegisterDetail/GetAllocatedCertificateRegisterDetail", {
                    comId: comId,
                    documentType: documentType
                });
            }

            getAvailableCertificateRegisterDetail(searchText: string, entityId: number, documentType: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CertificateRegisterDetail/GetAvailableCertificateRegisterDetail", {
                    entityId: entityId,
                    documentType: documentType,
                    searchText: searchText
                });
            }

            GetAvailableCertificateRegisterDetailCountryBased(countryId: number, searchText: string, entityId: number, documentType: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CertificateRegisterDetail/GetAvailableCertificateRegisterDetailCountryBased", {
                    countryId: countryId,
                    entityId: entityId,
                    documentType: documentType,
                    searchText: searchText
                });
            }
        }
    }
    angular.module("app").service("certificateRegisterDetailService", services.master.certificateRegisterDetailService);
}